import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, notification } from 'antd';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSwitch } from 'src/common/components/CustomSwitch/CustomSwitch';
import { Docs, Accept, Decline } from 'src/common/icons';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useSignDocumentsMutation } from '../../../common/redux/api/apiPatientSlice';

import styles from './styles.module.scss';
import { formatDate } from 'src/common/utils';

export const AGREE_TAB = '1';
export const DISAGREE_TAB = '2';

const items = [
  {
    label: (
      <div className={styles.TabsLabel}>
        <Accept />
        Если Вы согласны на участие в КИ
      </div>
    ),
    key: AGREE_TAB,
  },
  {
    label: (
      <div className={styles.TabsLabel}>
        <Decline />
        Если Вы отказываетесь от участия в КИ
      </div>
    ),
    key: DISAGREE_TAB,
  },
];
interface AgreementProps {
  id: string;
  createdDate: Date;
  documents: {
    name: string;
    title: string;
    fileUrl: string;
    optional: boolean;
    waiver: boolean;
  }[];
  backToList: () => void;
  changedActiveTab: string;
}
export const Agreement = ({ id, documents, createdDate, backToList, changedActiveTab }: AgreementProps) => {
  const [activeTab, setActiveTab] = useState(AGREE_TAB);
  const [signedList, setSignedList] = useState<string[]>([]);
  const [refusesList, setRefusesList] = useState<string[]>([]);
  const [agreeSignDisabled, setAgreeSignDisabled] = useState(documents?.filter((x) => !x.waiver)?.length > 0);
  const [refuseSignDisabled, setRefuseSignDisabled] = useState(documents?.filter((x) => x.waiver)?.length > 0);

  const [signDocuments, {isLoading}] = useSignDocumentsMutation();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    setActiveTab(changedActiveTab);
  }, [changedActiveTab]);

  useEffect(() => {
    let disabled = false;
    documents?.forEach((x) => {
      if (!x.waiver && !x.optional && !signedList.includes(x.name)) {
        disabled = true;
      }
    });
    setAgreeSignDisabled(disabled);
  }, [signedList]);

  useEffect(() => {
    let disabled = false;
    documents?.forEach((x) => {
      if (x.waiver && !x.optional && !refusesList.includes(x.name)) {
        disabled = true;
      }
    });
    setRefuseSignDisabled(disabled);
  }, [refusesList]);

  const changeSign = (checked: boolean, name: string) => {
    if (checked) {
      setSignedList([...signedList, name]);
    } else {
      setSignedList([...signedList.filter((x) => x !== name)]);
    }
  };

  const changeRefuse = (checked: boolean, name: string) => {
    if (checked) {
      setRefusesList([...refusesList, name]);
    } else {
      setRefusesList([...refusesList.filter((x) => x !== name)]);
    }
  };

  const refuseSign = () => {
    const request: any = { processId: id, isWaiver: true, processContext: { documentsToBeSigned: {} } };
    const now = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
    documents?.forEach((x) => {
      if (x.waiver && refusesList.includes(x.name)) {
        request.processContext.documentsToBeSigned[x.name] = {
          signatures: { patientSigned: true, patientSignedAt: now },
        };
      }
    });
    signDocuments(request)
      .then(() => {
        notification.success({ message: 'Отказ успешно подписан' });
        backToList();
      })
      .catch(() => {
        notification.error({ message: 'Ошибка подписания отказа' });
      });
  };

  const agreeSign = () => {
    const now = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');
    const request: any = { processId: id, isWaiver: false, processContext: { documentsToBeSigned: {} } };
    documents?.forEach((x) => {
      if (!x.waiver && signedList.includes(x.name)) {
        request.processContext.documentsToBeSigned[x.name] = {
          signatures: { patientSigned: true, patientSignedAt: now },
        };
      }
    });
    signDocuments(request)
      .then(() => {
        notification.success({ message: 'Согласие успешно подписано' });
        backToList();
      })
      .catch(() => {
        notification.error({ message: 'Ошибка подписания согласия' });
      });
  };

  return (
    <Row className={styles.Area}>
      <CustomCardTabs items={items} onChange={handleTabChange} className={styles.TabsLabels} activeKey={activeTab} />
      {activeTab === AGREE_TAB && (
        <>
          {documents
            ?.filter((document) => !document.waiver)
            ?.map((document, key) => (
              <Row key={key} className={styles.Block}>
                <Col className={styles.Info}>
                  <Docs />
                  <Row className={styles.Data}>
                    <Link to={document.fileUrl} target="_blank" className={styles.Title}>
                      {document.title}
                    </Link>
                    {createdDate && (
                      <Typography className={styles.Text}>
                        Получено: {formatDate(createdDate)}
                      </Typography>
                    )}
                  </Row>
                </Col>
                <CustomSwitch
                  checked={signedList.includes(document.name)}
                  onChange={(checked) => changeSign(checked, document.name)}
                  text="Подписать"
                  checkedText="Подписано"
                />
              </Row>
            ))}
          <CustomButton disabled={agreeSignDisabled || isLoading} onClick={agreeSign} isPrimal className={styles.Button}>
            <Typography>Отправить</Typography>
          </CustomButton>
        </>
      )}
      {activeTab === DISAGREE_TAB && (
        <>
          {documents?.filter((document) => document.waiver)?.length > 0 ? (
            <>
              {documents
                ?.filter((document) => document.waiver)
                ?.map((document, key) => (
                  <Row key={key} className={styles.Block}>
                    <Col className={styles.Info}>
                      <Docs />
                      <Row className={styles.Data}>
                        <Link to={document.fileUrl} target="_blank" className={styles.Title}>
                          {document.title}
                        </Link>
                        {createdDate && (
                          <Typography className={styles.Text}>
                            Получено: {formatDate(createdDate)}
                          </Typography>
                        )}
                      </Row>
                    </Col>
                    <CustomSwitch
                      checked={refusesList.includes(document.name)}
                      onChange={(checked) => changeRefuse(checked, document.name)}
                      text="Подписать"
                      checkedText="Подписано"
                    />
                  </Row>
                ))}
            </>
          ) : (
            <Row className={styles.Message}>
              <Typography.Text>Уважаемый пациент!</Typography.Text>
              <Typography.Text>
                Если у Вас есть сомнения либо остались вопросы по клиническому исследованию, пожалуйста, обсудите их со
                своим врачом. В случае, если Вы откажетесь от участия на данном этапе - врач не сможет пригласить Вас в
                исследование повторно. Также обращаем внимание, что Вы сможете отказаться от участия в любой момент по
                ходу исследования.
              </Typography.Text>
              <Typography.Text>
                Вы желаете отказаться от участия в предложенном Вам клиническом исследовании, пожалуйста, нажмите кнопку
                ниже.
              </Typography.Text>
            </Row>
          )}
          <CustomButton disabled={refuseSignDisabled || isLoading} onClick={refuseSign} isPrimal className={styles.RedButton}>
            <Typography>Отказаться от участия в клиническом исследовании</Typography>
          </CustomButton>
        </>
      )}
    </Row>
  );
};
