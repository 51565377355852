import KeyCloakService from '../../../keycloak';
import * as signalR from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import {
  CalendarRecord,
  MedCarLastUpdate,
  MedcardCalendarYearRecord,
  StatProfile,
  MedcardDocument
} from 'src/common/types';
import ExpiryMap from 'expiry-map';
import { useAppSelector } from '../../../app/hooks';
import {LogLevel} from "@microsoft/signalr";

const URL = `${window.__RUNTIME_CONFIG__.REACT_APP_API}/streams`;

class Connector {
  private connection: signalR.HubConnection;
  private static instance: Connector;
  private constructor(keycloakObj: any) {
    const options = {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
      ...{ accessTokenFactory: () => KeyCloakService(keycloakObj).getAccessToken() ?? '' },
    };
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL, options).withAutomaticReconnect()
        .configureLogging(LogLevel.None).build();
    this.connection.start().catch((err) => document.write(err));
  }
  public subscribe(channel: string, callback: (data: any) => void) {
    this.connection.on(channel, callback);
  }
  public off(channel: string, callback: (data: any) => void) {
    this.connection.off(channel, callback);
  }
  public static getInstance(keycloakObj: any): Connector {
    if (!Connector.instance) Connector.instance = new Connector(keycloakObj);
    return Connector.instance;
  }
}

export interface BaseCrIdMessage<TDataType> {
  crId: string;
  data: TDataType;
}

export class CachedMessagesHandler {
  private static _instances: { [name: string]: CachedMessagesHandler } = {};
  private _cache: ExpiryMap;
  private _connector: Connector;
  private _timers: Map<string, number>;
  public constructor(channel: string, keycloakObj: any) {
    this._connector = Connector.getInstance(keycloakObj);
    this._timers = new Map<string, number>();

    this._cache = new ExpiryMap(1000000);
    this._connector.subscribe(channel, this.processor.bind(this));
  }
  private processor(item: any) {
    var crIdMessage = item as BaseCrIdMessage<any>;
    if (crIdMessage) {
      if (!this._cache.has(crIdMessage.crId)) {
        this._cache.set(crIdMessage.crId, [crIdMessage]);
        return;
      }

      var data = this._cache.get(crIdMessage.crId) as [];
      data.push(crIdMessage as never);
      this._cache.set(crIdMessage.crId, data);
    }
  }

  public off(CrId: string) {
    var timer = this._timers.get(CrId);
    clearInterval(timer);
    this._timers.delete(CrId);
  }
  public subscribe(CrId: string, pp: (data: any) => void) {
    const doWork = () => {
      var item = this._cache.get(CrId) as [];
      if (!item) {
        return;
      }

      var first = item.pop();
      if (first) {
        pp(first);
      }
    };

    this._timers.set(CrId, window.setInterval(doWork.bind(this), 100));
  }

  public static getInstance(channel: string, keycloakObj: any): CachedMessagesHandler {
    if (!this._instances[channel]) {
      this._instances[channel] = new CachedMessagesHandler(channel, keycloakObj);
    }

    return this._instances[channel];
  }
}

function useBaseApiStreamSlice<TDataType, TMessageType extends BaseCrIdMessage<TDataType>>(
  processor: (data: TDataType) => any,
) {
  const [CrId, SetCrId] = useState<string | undefined>();
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  useEffect(() => {
    if (!CrId) {
      return;
    }

    const pp = (data: any) => {
      try {
        var item = data as TMessageType;
        if (item) {
          processor(item.data);
        }
      } catch (err) {
        debugger;
      }
    };

    const connection = CachedMessagesHandler.getInstance('DataIsReady', keycloakObj);
    connection.subscribe(CrId, pp);

    return () => {
      if (!CrId) {
        return;
      }
      connection.off(CrId);
    };
  }, [CrId]);

  return [SetCrId];
}

export const streamMedCardApi = useBaseApiStreamSlice<
  MedcardCalendarYearRecord[],
  BaseCrIdMessage<MedcardCalendarYearRecord[]>
>;

//StatProfile
export const streamStatProfileApi = useBaseApiStreamSlice<StatProfile, BaseCrIdMessage<StatProfile>>;

export const streamMedCardDateApi = useBaseApiStreamSlice<MedCarLastUpdate, BaseCrIdMessage<MedCarLastUpdate>>;

export const streamMedcardCalendarCasesApi = useBaseApiStreamSlice<CalendarRecord[], BaseCrIdMessage<CalendarRecord[]>>;
export const streamProfileDocumentsApi = useBaseApiStreamSlice<MedcardDocument[], BaseCrIdMessage<MedcardDocument[]>>;
export const streamProfileDocumentsCountApi = useBaseApiStreamSlice<number, BaseCrIdMessage<number>>;
