import { useEffect, useState } from 'react';
import { Row, Col, Typography, notification, Spin } from 'antd';
import { CustomSwitch } from 'src/common/components/CustomSwitch/CustomSwitch';
import { Docs, OkIcon } from 'src/common/icons';
import cx from 'classnames';

import styles from './styles.module.scss';
import { formatDate } from 'src/common/utils';

interface SignedAgreementProps {
  id: string;
  createdDate: Date;
  documents: {
    name: string;
    title: string;
    fileUrl: string;
    optional: boolean;
    waiver: boolean;
    patientSignedDate: Date;
    doctorSignedDate: Date;
  }[];
  backToList: () => void;
}

export const SignedAgreement = ({ id, documents, createdDate, backToList }: SignedAgreementProps) => {
  const [opened, setOpened] = useState<string[]>([]);
  const [signedDate, setSignedDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSignedDate(documents?.find((x) => !x.waiver)?.patientSignedDate || null);
  }, [documents]);

  const openDocument = async (url: string) => {
    setLoading(true);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl, '_blank', 'noopener,noreferrer');

      setTimeout(() => URL.revokeObjectURL(blobUrl), 30000);
    } catch {
      notification.error({ message: 'Ошибка при загрузке документа' });
    }
    setLoading(false);
  };

  return (
    <Row className={styles.Area}>
      <Typography className={styles.BlockTitle}>Подписанные документы</Typography>
      {signedDate && (
        <Typography className={styles.OkNote}>
          <div className={styles.OkIconContainer}>
            <OkIcon />
          </div>
          Вы подписали согласие на участие {formatDate(signedDate)}
        </Typography>
      )}
      {documents
        ?.filter((document) => !document.waiver)
        ?.map((document, key) => (
          <Row key={key} className={styles.Block}>
            <Col className={styles.Info}>
              <Docs />
              <Row className={styles.Data}>
                <Typography.Link
                  onClick={(e) => {
                    e.preventDefault();
                    openDocument(document.fileUrl);
                  }}
                >
                  <div className={styles.Title}>
                    {document.title}
                    {loading && <Spin size="small" />}
                  </div>
                </Typography.Link>
                {document.patientSignedDate && (
                  <Typography className={styles.Text}>
                    Подписано пациентом: {formatDate(document.patientSignedDate)}
                  </Typography>
                )}
                <Row>
                  <Typography.Text
                    onClick={() =>
                      setOpened(
                        opened.includes(document.name)
                          ? [...opened.filter((x) => x !== document.name)]
                          : [...opened, document.name],
                      )
                    }
                    className={cx(styles.MessageHeader, { [styles.Open]: opened.includes(document.name) })}
                  >
                    История
                  </Typography.Text>
                  <Row className={cx(styles.MessageBody, { [styles.Open]: opened.includes(document.name) })}>
                    {document.doctorSignedDate && (
                      <Typography className={styles.Text}>
                        Подписано врачом: {formatDate(document.doctorSignedDate)}
                      </Typography>
                    )}
                    {document.patientSignedDate && (
                      <Typography className={styles.Text}>
                        Подписано пациентом: {formatDate(document.patientSignedDate)}
                      </Typography>
                    )}
                    {createdDate && (
                      <Typography className={styles.Text}>Получено: {formatDate(createdDate)}</Typography>
                    )}
                  </Row>
                </Row>
              </Row>
            </Col>
            <CustomSwitch disabled={true} checked={true} text="Подписать" checkedText="Подписано" />
          </Row>
        ))}
    </Row>
  );
};
