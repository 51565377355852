import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { CustomAutocompleteSearch } from 'src/common/components/CustomAutocompleteSearch/CustomAutocompleteSearch';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { CustomSelector } from 'src/common/components/CustomSelector/CustomSelector';
import { DictionaryItem, MedcardDocument } from 'src/common/types';
import { DocumentType, DocumentUploadedByWhomType } from 'src/common/types/enums';
import { MedCardDocument } from '../MedCardDocument/MedCardDocument';
import { MedcardDocumentLoadForm } from '../MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { Plus } from 'src/common/icons';
import { SliderRow } from 'src/common/components/SliderRow/SliderRow';
import { StatisticsTab } from 'src/common/components/StatisticsTab/StaticticsTab';
import { removeDuplicatesFromSpecialities } from '../../../common/utils';
import { useGetFerSpecialitiesQuery } from 'src/common/redux/api/apiDictionarySlice';
import Analyze from 'src/assets/analyze 1.png';
import Documents from 'src/assets/DocumentIcon.png';
import Drugs from 'src/assets/drugs.png';
import Folder from 'src/assets/Folder.png';
import Operations from 'src/assets/operations.png';
import Printer from 'src/assets/Printer.png';
import Referral from 'src/assets/Referral.png';
import Syringe from 'src/assets/syringe.png';
import Typography from 'antd/es/typography/Typography';
import Xray from 'src/assets/x-ray 1.png';
import styles from './styles.module.scss';

const ICON_TYPES: Record<string, string> = {
  [DocumentType.Analysis]: Analyze,
  [DocumentType.MedicalReport]: Documents,
  [DocumentType.Referral]: Referral,
  [DocumentType.Examination]: Xray,
  [DocumentType.Vaccination]: Syringe,
  [DocumentType.Recipe]: Drugs,
  [DocumentType.Surgery]: Operations,
  [DocumentType.Other]: Printer,
};

export const getIconByDocumentType = (documentType: DocumentType) => ICON_TYPES[documentType];

interface MedCardDocumentsProps {
  idShared?: string;
  documents?: MedcardDocument[];
  showFilters?: boolean;
}

export const MedCardDocuments = ({ showFilters = false, documents, idShared }: MedCardDocumentsProps) => {
  const [isDocumentOpen, setDocumentOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(-1);
  const [filteredDocs, setFilteredDocs] = useState<MedcardDocument[]>();
  const [uploadedBy, setUploadedBy] = useState<DocumentUploadedByWhomType | string>();
  const [doctorSpecialtyInput, setDoctorSpecialtyInput] = useState<string>();
  const [doctorSpecialtyName, setDoctorSpecialtyName] = useState<string>();

  useEffect(() => {
    if (documents && documents.length > 0) {
      let initialDocs = [...documents];
      if (activeTab > -1) {
        initialDocs = initialDocs.filter((document) => document.documentType === activeTab);
      }

      if (uploadedBy !== undefined && uploadedBy !== 'Все') {
        initialDocs = initialDocs.filter((document) => document.uploadedByWhomType === Number(uploadedBy));
      }

      if (uploadedBy === 'Все') {
        initialDocs = initialDocs.filter((document) => activeTab === -1 || document.documentType === activeTab);
      }

      if (doctorSpecialtyName) {
        initialDocs = initialDocs.filter(
          (document) =>
            document.doctorSpecialtyName &&
            document.doctorSpecialtyName.toLowerCase().includes(doctorSpecialtyName.toLowerCase()),
        );
      }
      setFilteredDocs(initialDocs);
    } else {
      setFilteredDocs([]);
    }
  }, [activeTab, doctorSpecialtyName, documents, uploadedBy]);

  const handleDocumentOpen = () => setDocumentOpen(true);
  const handleDocumentClose = () => setDocumentOpen(false);

  const tabsItems = useMemo(
    () => [
      { name: 'Все', key: -1, icon: Folder, count: documents?.length },
      {
        name: 'Заключения',
        key: DocumentType.MedicalReport,
        icon: Documents,
        count: documents?.filter((document) => document.documentType === DocumentType.MedicalReport).length,
      },
      {
        name: 'Анализы',
        key: DocumentType.Analysis,
        icon: Analyze,
        count: documents?.filter((document) => document.documentType === DocumentType.Analysis).length,
      },
      {
        name: 'Исследования',
        key: DocumentType.Examination,
        icon: Xray,
        count: documents?.filter((document) => document.documentType === DocumentType.Examination).length,
      },
      // {
      //   name: 'Направления',
      //   key: DocumentType.Referral,
      //   icon: Referral,
      //   count: documents?.filter((document) => document.documentType === DocumentType.Referral).length,
      // },
      {
        name: 'Рецепты',
        key: DocumentType.Recipe,
        icon: Drugs,
        count: documents?.filter((document) => document.documentType === DocumentType.Recipe).length,
      },
      {
        name: 'Вакцинация',
        key: DocumentType.Vaccination,
        icon: Syringe,
        count: documents?.filter((document) => document.documentType === DocumentType.Vaccination).length,
      },
      {
        name: 'Другое',
        key: DocumentType.Other,
        icon: Printer,
        count: documents?.filter((document) => document.documentType === DocumentType.Other).length,
      },
    ],
    [documents],
  );

  const { data: specialities } = useGetFerSpecialitiesQuery();

  const specialitiesOptions = useMemo(() => {
    const doctorSpecialities = filteredDocs?.map((doc) => doc.doctorSpecialtyName).filter(Boolean);

    return removeDuplicatesFromSpecialities(
      specialities
        ?.filter(({ name }) => doctorSpecialities?.some((speciality) => speciality.includes(name)))
        .map(({ name, code }: DictionaryItem) => ({
          key: code,
          value: name,
          label: name,
        })) || [],
    );
  }, [filteredDocs, specialities]);

  const uniqueSpecialitiesOptions = useMemo(
    () => removeDuplicatesFromSpecialities(specialitiesOptions),
    [specialitiesOptions],
  );

  const uploadedByOptions = useMemo(
    () => [
      { label: 'Пaциент', value: DocumentUploadedByWhomType.Patient as unknown as string },
      { label: 'Клиника', value: DocumentUploadedByWhomType.Clinic as unknown as string },
      { label: 'Все', value: 'Все' },
    ],
    [],
  );

  const handleChangeActiveTab = (tabName: string, tabKey?: number) => {
    if (tabKey || tabKey === 0) {
      setActiveTab(tabKey);
    }
  };

  const handleUploadedChange = (val: string) => {
    val === 'Все' || val === undefined ? setUploadedBy('Все') : setUploadedBy(Number(val));
  };

  const handleUploadedClear = () => {
    setUploadedBy('Все');
  };

  const handleDoctorSpecialtyChange = (id: string, option: { value: string; label: string; key: string }) => {
    setDoctorSpecialtyName(option.label);
    setDoctorSpecialtyInput(option.label);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoctorSpecialtyInput(e.target.value);
  };

  const tabsList = useMemo(
    () => [
      ...tabsItems.map((tabItem) => (
        <StatisticsTab
          key={tabItem.name}
          className={activeTab === tabItem.key ? styles.Active : ''}
          icon={tabItem.icon}
          tabName={tabItem.name}
          tabNumber={tabItem.count ?? 0}
          tabKey={tabItem.key}
          onClick={handleChangeActiveTab}
        />
      )),
      <Col className={`${styles.AddTab} ${idShared && styles.HiddenTab}`} onClick={handleDocumentOpen}>
        <Plus />
        <Typography className={styles.AddTabText}>Загрузить документ</Typography>
      </Col>,
    ],
    [activeTab, tabsItems, idShared],
  );

  return (
    <Layout className={styles.DocumentsWrapper}>
      {showFilters && (
        <>
          <Typography className={styles.Title}>Медицинские документы</Typography>
          <SliderRow className={styles.DocumentsSliderRow} arrowsHide>
            {tabsList}
          </SliderRow>

          <Row className={styles.FilterRow}>
            <CustomSelector
              options={uploadedByOptions}
              floatLabel="Кем загружен"
              className={styles.Selector}
              onChange={handleUploadedChange}
              value={uploadedBy as unknown as string}
              allowClear
              onClear={handleUploadedClear}
            />
            <CustomAutocompleteSearch
              className={`${styles.Selector} ${styles.DesktopSelector}`}
              floatLabel="Укажите специализацию врача"
              options={uniqueSpecialitiesOptions || []}
              handleChangeValue={handleDoctorSpecialtyChange}
              value={doctorSpecialtyInput}
              onChange={handleChange}
              filterOption={(inputValue, option) =>
                option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase() || '') !== -1
              }
              allowClear
            />
            <CustomAutocompleteSearch
              className={styles.MobileSelector}
              floatLabel="Специализация"
              options={uniqueSpecialitiesOptions || []}
              handleChangeValue={handleDoctorSpecialtyChange}
              value={doctorSpecialtyInput}
              onChange={handleChange}
              filterOption={(inputValue, option) =>
                option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase() || '') !== -1
              }
              allowClear
            />
          </Row>
          <Typography className={styles.GreyText}>Всего документов: {filteredDocs?.length}</Typography>
        </>
      )}
      {filteredDocs?.map((document) => (
        <MedCardDocument
          idShared={idShared}
          document={document}
          key={document.idMilaUserDocument ? document.idMilaUserDocument : document.idExternalDocument}
        />
      ))}
      {showFilters && !idShared && (
        <>
          <CustomButton isPrimal className={styles.AddButton} onClick={handleDocumentOpen}>
            <Typography className={styles.ButtonText}>
              <Plus />
              Загрузить документ
            </Typography>
          </CustomButton>
          <CustomModal isOpen={isDocumentOpen} onCancelClick={handleDocumentClose}>
            <MedcardDocumentLoadForm onFinish={handleDocumentClose} idShared={idShared} />
          </CustomModal>
        </>
      )}
    </Layout>
  );
};
