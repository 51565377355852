import { Spin, SpinProps } from 'antd';
import styles from './styles.module.scss';
import { FC } from 'react';
import cx from 'classnames';

type CustomSpinnerProps = {
  className?: string;
  componenentProps?: SpinProps;
};

export const CustomSpinner: FC<CustomSpinnerProps> = ({ className, ...componenentProps }: CustomSpinnerProps) => (
  <Spin className={cx(styles.Spinner, className)} {...componenentProps} />
);
