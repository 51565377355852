import { ChangeEvent, FC, useCallback, useState } from 'react';
import { MaskedInput } from 'antd-mask-input';
import { Form, Input } from 'antd';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Rule } from 'antd/lib/form';
import { formatPhone } from 'src/common/utils';

interface PhoneInputProps {
  value?: string;
  onChange?: (value: string | null) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
}

export const PhoneInput: FC<PhoneInputProps> = ({ value, onChange, name = 'phone', className, disabled }) => {
  const PHONE_MASK = '+7 (000) 000-0000';
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value.replace(/[^0-9]/g, '').trim();
      const isEmpty = !rawValue || rawValue === '7';
      const formattedValue = formatPhone(rawValue);

      if (isEmpty) {
        onChange?.(null);
      } else {
        onChange?.(formattedValue);
      }
    },
    [onChange],
  );

  const rules: Rule[] = [
    {
      required: true,
      message: 'Пожалуйста, введите корректный номер телефона',
      pattern: /^\+7 \(\d{3}\) \d{3}-\d{4}$/,
    },
  ];

  return (
    <Form.Item name={name} rules={rules}>
      {isFocused || value ? (
        <MaskedInput
          name={name}
          value={value}
          mask={PHONE_MASK}
          disabled={disabled}
          onChange={handleOnChange}
          className={cx(styles.inputLabel, className)}
          onBlur={() => !value && setIsFocused(false)}
          autoFocus
        />
      ) : (
        <Input
          name={name}
          value=""
          className={cx(styles.inputLabel, className)}
          onFocus={() => setIsFocused(true)}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
};
